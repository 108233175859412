<template>
  <div class="page">
    <div class="top_title">
      <div>
        首页 > 会议 > 一键报名
      </div>
    </div>
    <!--  -->
    <div class="pay_box">
      <div class="status_box">
        <img src="@/assets/img/meeting/pay_icon04.png"
             alt="">
        <p>订单已提交成功</p>
      </div>
      <div class="line_box">
        <div class="left">
          <p>应付金额：</p>
          <p><span>¥</span>{{objInfo.amount}}</p>
        </div>
        <div class="right">
          订单编码：{{objInfo.order_no}}
        </div>
      </div>
      <div class="pay_li">
        <p>平台支付：</p>
        <template v-if='payList && payList.length > 0'>
          <el-button class="row"
                     :loading='activePay == row.pay_type_id && saveLoading'
                     @click="changeType(row.pay_type_id)"
                     :class='activePay == row.pay_type_id ? "activeRow" :""'
                     v-for="(row, index) in payList"
                     :key="index">
            <img :src="pay_list.find(c=>c.type == row.pay_type_id).icon"
                 alt="">
            <span>{{ pay_list.find(c=>c.type == row.pay_type_id).name }}</span>
            <img src="@/assets/img/meeting/pay_icon01.png"
                 v-if='activePay == row.pay_type_id'
                 class="activeImg"
                 alt="">
          </el-button>
        </template>

      </div>
      <div class="bottom_box"
           v-if='payList.find(c => c.pay_type_id == 2)'>
        <img src="@/assets/img/meeting/pay_icon05.png"
             class="tip"
             alt="">
        <div>
          <p>使用微信扫一扫</p>
          <div class="code"
               ref="qrCodeUrl"></div>
        </div>

      </div>
    </div>
    <!--  -->
    <el-dialog :visible.sync="isShow_pop"
               :close-on-click-modal='false'
               :show-close='false'
               center
               width="550px">
      <div class="curretInfo_box">
        <div>提示信息</div>
        <div v-if='payList.find(c=>c.pay_type_id == activePay)'>
          <p v-for='(row,i) in payList.find(c=>c.pay_type_id == activePay).intro'
             :key='i'>{{row}}</p>
        </div>
        <el-row class="button_box">
          <el-button class="button button1"
                     @click="isShow_pop = false">取消</el-button>
          <el-button class="button button2"
                     @click="onPay()">确定</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_payChannel, set_ticketPay, get_schedule_list, get_hotelOrder, set_payRoom } from '@/utils/Api/meeting'
import QRCode from 'qrcodejs2'
export default {
  components: { QRCode },
  props: [],
  data () {
    return {
      pay_list: [
        { name: '支付宝', type: 1, icon: require('@/assets/img/meeting/pay_icon03.png') },
        { name: '微信', type: 2, icon: require('@/assets/img/meeting/pay_icon02.png') },
        { name: '现场支付', type: 3, icon: require('@/assets/img/meeting/xianchang.png') },
        { name: '线下转账', type: 4, icon: require('@/assets/img/meeting/xianxia.png') }
      ],
      count: 0,
      payList: [],
      meetingId: null,
      activePay: null,
      orderId: null,
      saveLoading: false,
      WXpay: null,
      isShow_pop: false,
      objInfo: {},
      ZFpay: null,
      WXpay: null,
      type: null,
      iswx: null,
      setQueryWxPayResult: null
    }
  },
  methods: {
    async  getPayType () {
      var res = await get_payChannel({ meet_id: this.meetingId })
      if (res && res.length > 0) {
        this.payList = res
        this.activePay = this.payList[0].pay_type_id
        if (this.payList.find(c => c.pay_type_id == 2)) {
          this.activePay = 2
          this.onPay()
        }
      }
    },
    async getDetail () {
      if (this.type == 1) {
        var res = await get_schedule_list({ page: 1, limit: 1, type: 2, meet_id: this.orderId })
      } else {
        var res = await get_hotelOrder({ page: 1, limit: 1, type: 2, room_order_id: this.orderId })
        this.payList = [{ id: 293, meet_id: 9, pay_type_id: 1, intro: [] }, { id: 294, meet_id: 9, pay_type_id: 2, intro: [] }
        ]
      }
      if (res) {
        this.objInfo = res.data[0]
        if (this.type == 1) {
          this.meetingId = this.objInfo.meet.id
          this.getPayType()
        } else {
          this.activePay = 2
          this.onPay()
        }
      }
    },
    async changeType (type) {
      if (this.saveLoading) return
      this.activePay = type;
      if (this.activePay == 3) {
        if (this.payList.find(c => c.pay_type_id == this.activePay).intro.length > 0) {
          this.isShow_pop = true
        } else {
          this.onPay()
        }
      } else if (this.activePay == 4) {
        if (this.payList.find(c => c.pay_type_id == this.activePay).intro.length > 0) {
          this.isShow_pop = true
        } else {
          this.onPay()
        }
      } else {
        this.onPay()
      }
    },
    async onPay () {
      this.saveLoading = true
      try {
        var res = null
        if (this.type == 1) {
          let params = {
            join_meet_id: this.orderId,
            amount: this.objInfo.amount,
            pay_type: Number(this.objInfo.amount) <= 0 ? 5 : this.activePay,
            duan_type: 2,
            ticket_id: this.objInfo.meet.id,
          }
          res = await set_ticketPay(params)
        } else {
          let params = {
            order_room_id: this.orderId,
            type: this.activePay,
            duan_type: 2
          }
          res = await set_payRoom(params)
        }
        this.saveLoading = false
        if (res) {
          if (this.activePay == 3 || this.activePay == 4) {
            this.$router.replace({
              path: this.type == 1 ? '/personal/meetingOrderDetail' : '/personal/hotelDetails', query: { oderId: this.orderId }
            })
          } else if (this.activePay == 1) {
            const div = document.createElement('payHtml');
            div.innerHTML = res
            document.body.appendChild(div);
            setTimeout(() => {
              document.forms['alipaysubmit'].submit()
            })
          } else if (this.activePay == 2) {
            if (!this.iswx) this.wxcodeqr(res.wxpay)
          }
        }
      } catch{
        this.saveLoading = false
      }
    },
    wxcodeqr (value) {
      this.iswx = true
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: value,
        width: 117,
        height: 117,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
      this.setQueryWxPayResult = setInterval(() => {
        this.count++
        if (this.count >= 100) {
          this.$router.replace(`/personal/meetingOrder?tabActive${this.type == 1 ? 0 : 1}`)
          return
        }
        this.queryWxPayResult(this.code)
      }, 3000)
    },
    async queryWxPayResult () {
      if (this.type == 1) {
        var res = await get_schedule_list({ page: 1, limit: 1, type: 2, meet_id: this.orderId })
      } else {
        var res = await get_hotelOrder({ page: 1, limit: 1, type: 2, room_order_id: this.orderId })
      }
      if (res) {
        if (this.type == 1) {
          if (res.data[0].pay_status != 2) {
            clearInterval(this.setQueryWxPayResult)
            this.setQueryWxPayResult = null
            this.$message.success(res.data[0].pay_status == 1
              ? '待签到'
              : res.data[0].pay_status == 3
                ? '已取消'
                : res.data[0].pay_status == 4
                  ? '已取消'
                  : res.data[0].pay_status == 5
                    ? '已退款' : '')
            this.$router.replace('/personal/meetingOrder')
          } else {

          }
        } else {
          if (res.data[0] && res.data[0].status != 1) {
            clearInterval(this.setQueryWxPayResult)
            this.setQueryWxPayResult = null
            this.$message.success(res.data[0].status == 1
              ? '待支付'
              : res.data[0].status == 2
                ? '已支付'
                : res.data[0].status == 3
                  ? '已超时'
                  : res.data[0].status == 4
                    ? '已取消'
                    : res.data[0].status == 5
                      ? '退款成功'
                      : '')
            this.$router.replace('/personal/meetingOrder?tabActive=1')
          }
        }
      }
    },
  },
  created () {
    this.orderId = this.$route.query.orderId
    this.type = this.$route.query.type
    this.getDetail()
  },
  mounted () { },
  destroyed () {
    clearInterval(this.setQueryWxPayResult)
    this.setQueryWxPayResult = null
  },
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.top_title {
  background-color: #fff;
  border-top: 3px solid #47d7e3;
  > div {
    height: 60px;
    line-height: 60px;
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
  }
}
.page {
  background-color: #fff;
}
.pay_box {
  margin: 0 auto;
  width: 1200px;
  height: 691px;
  border: 1px dashed #999;
  margin-bottom: 70px;
}
.status_box {
  margin-top: 48px;
  margin-bottom: 48px;
  img {
    width: 84px;
    height: 84px;
    margin-bottom: 32px;
  }
  p {
    font-size: 18px;
    color: #333;
  }
}
.line_box {
  margin-bottom: 32px;
  color: #19191b;
  font-size: 16px;
  width: 1200px;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff9f5;
  .left {
    text-align: left;
    width: 60%;
    padding: 0 32px;
    box-sizing: border-box;
    > p {
      display: inline-block;
    }
    > p:nth-child(2) {
      margin: 0 10px;
      font-weight: 700;
      font-size: 20px;
      color: #ff2b2b;
      span {
        font-size: 12px;
      }
    }
  }
  .right {
    width: 40%;
    text-align: right;
    padding-right: 30px;
    box-sizing: border-box;
  }
}
.pay_li {
  padding: 0 32px;
  text-align: left;
  font-size: 16px;
  color: #19191b;
  display: flex;
  flex-wrap: wrap;
  .el-button {
    vertical-align: middle;
    cursor: pointer;
    padding: 0;
    position: relative;
    width: 186px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #c6c6c6;
    border-radius: 1px;
    font-size: 16px;
    color: #19191b;
    margin-right: 12px;
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
  }
  .activeRow {
    border: 1px solid #47d7e3;
  }
  .activeImg {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 0 !important;
  }
}
.bottom_box {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 78px;
  .tip {
    width: 273px;
    height: 200px;
    margin-right: 30px;
  }
  > div {
    border-left: 1px solid #eeeeee;
    padding-left: 100px;
    p {
      margin-bottom: 8px;
      font-size: 14px;
      color: #545454;
    }
    .code {
      width: 117px;
      height: 117px;
    }
  }
}
.curretInfo_box {
  padding: 0 20px;
  > div:nth-child(1) {
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    color: #333;
    margin-bottom: 32px;
    font-weight: 700;
  }
  .el-input__inner {
    height: 46px;
  }
  .button_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 54px;
    .button {
      width: 168px;
      height: 46px;
      background: #ffffff;
      border: 1px solid #c6c6c6;
      border-radius: 2px;
      color: #979797;
      font-size: 16px;
    }
    .button2 {
      border: 1px solid #47d7e3;
      background-color: #47d7e3;
      color: #fff;
    }
  }
}
</style>
